import { emptySplitApi as api } from "./emptyApi";
export const addTagTypes = ["Root", "Users", "Places"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      get: build.query<GetApiResponse, GetApiArg>({
        query: () => ({ url: `/` }),
        providesTags: ["Root"],
      }),
      postUsersLogin: build.mutation<
        PostUsersLoginApiResponse,
        PostUsersLoginApiArg
      >({
        query: (queryArg) => ({
          url: `/users/login`,
          method: "POST",
          body: queryArg.bodyUsersUserLogin,
        }),
        invalidatesTags: ["Users"],
      }),
      getUsersMe: build.query<GetUsersMeApiResponse, GetUsersMeApiArg>({
        query: () => ({ url: `/users/me` }),
        providesTags: ["Users"],
      }),
      getUsersMyplaces: build.query<
        GetUsersMyplacesApiResponse,
        GetUsersMyplacesApiArg
      >({
        query: () => ({ url: `/users/myplaces` }),
        providesTags: ["Users"],
      }),
      getPlacesGotoByPlaceId: build.query<
        GetPlacesGotoByPlaceIdApiResponse,
        GetPlacesGotoByPlaceIdApiArg
      >({
        query: (queryArg) => ({ url: `/places/goto/${queryArg.placeId}` }),
        providesTags: ["Places"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as api };
export type GetApiResponse = /** status 200 Successful Response */ any;
export type GetApiArg = void;
export type PostUsersLoginApiResponse =
  /** status 200 Successful Response */ TokenSchema;
export type PostUsersLoginApiArg = {
  bodyUsersUserLogin: BodyUsersUserLogin;
};
export type GetUsersMeApiResponse = /** status 200 Me data retrieved */ User;
export type GetUsersMeApiArg = void;
export type GetUsersMyplacesApiResponse =
  /** status 200 Places retrieved for current user */ Place[];
export type GetUsersMyplacesApiArg = void;
export type GetPlacesGotoByPlaceIdApiResponse =
  /** status 200 Place retrieved */ Place;
export type GetPlacesGotoByPlaceIdApiArg = {
  placeId: string;
};
export type TokenSchema = {
  access_token: string;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type BodyUsersUserLogin = {
  grant_type?: string;
  username: string;
  password: string;
  scope?: string;
  client_id?: string;
  client_secret?: string;
};
export type User = {
  id: string;
  email: string;
  password: string;
  pass_id: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  address: string;
  age: number;
  is_admin?: boolean;
};
export type Place = {
  id: string;
  address: string;
  phone_number: string;
  required_pass_level: number;
  required_age_level: number;
};
export const {
  useGetQuery,
  useLazyGetQuery,
  usePostUsersLoginMutation,
  useGetUsersMeQuery,
  useLazyGetUsersMeQuery,
  useGetUsersMyplacesQuery,
  useLazyGetUsersMyplacesQuery,
  useGetPlacesGotoByPlaceIdQuery,
  useLazyGetPlacesGotoByPlaceIdQuery,
} = injectedRtkApi;
