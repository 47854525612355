import { Button, Typography, useTheme } from "@mui/material";
import { useColorMode } from "../hooks/CustomThemeProvider";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

export function ToggleColorMode() {
    const theme = useTheme();
    const colorMode = useColorMode();
    return (
        <Button onClick={colorMode.toggleColorMode} >
            {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />} <Typography textAlign="center"> {theme.palette.mode} mode </Typography>
        </Button>
    );
}