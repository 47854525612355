import React, { FC, ReactNode } from "react";
import decodeJwt from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router-dom';
import { BodyUsersUserLogin, usePostUsersLoginMutation } from "../store";
import { useDispatch , useSelector} from "react-redux";
import { setCredentials } from "../store/authSlice";
import { selectCurrentToken } from "../store/authSlice";

export const AuthContext = React.createContext(null as any);

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  
  const token = useSelector(selectCurrentToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [login] = usePostUsersLoginMutation();

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    console.log({
      username: formData.get('username'),
      password: formData.get('password'),
    });
    const params : BodyUsersUserLogin = {
      username: formData.get('username')?.toString()??"",
      password: formData.get('password')?.toString()??""
    }

    const token : any = await login({bodyUsersUserLogin: params}).unwrap()

    if (token){
      const decodedToken: any = decodeJwt(token['access_token']);
      dispatch(setCredentials({ user : decodedToken.user_id, token : token['access_token']  }));
      const origin = location.state?.from?.pathname || '/';
      navigate(origin);
    }

  };

  const handleLogout = () => {
    dispatch(setCredentials({ user : null, token : null }));
    navigate('/logout');
  };

  const value = {
    token,
    onLogin: handleLogin,
    onLogout: handleLogout,
  };

  return (
    <AuthContext.Provider value={value}>
      {[children]}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};