import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Home, Login, PlacesList , Logout, Profile } from '../components';
import { ProtectedRoute } from './ProtectedRoute';
import { PlaceShow } from '../components/PlaceShow';

export const Router: FC = () => {

  return (

    <Routes>

      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
      <Route path="/places" element={<ProtectedRoute><PlacesList /></ProtectedRoute>} />
      <Route path="/place/:place_id" element={<ProtectedRoute><PlaceShow /></ProtectedRoute>} />
      <Route path="/" element={<Home />} />

    </Routes>
  );
};


