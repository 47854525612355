import { ThemeProvider, createTheme ,LinkProps } from "@mui/material";
import React, { FC, ReactNode } from "react";
import {
    Link as RouterLink,
    LinkProps as RouterLinkProps
  } from 'react-router-dom';

const ColorModeContext = React.createContext({ toggleColorMode: () => { } });

export const CustomThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [mode, setMode] = React.useState<'light' | 'dark'>('light');
    const colorMode = React.useMemo(
      () => ({
        toggleColorMode: () => {
          setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        },
      }),
      [],
    );
    const LinkBehavior = React.forwardRef<
      any,
      Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
    >((props, ref) => {
      const { href, ...other } = props;
      // Map href (MUI) -> to (react-router)
      return <RouterLink ref={ref} to={href} {...other} />;
    });
    const theme = React.useMemo(
      () =>
        createTheme({
          spacing: 4,
          palette: {
            mode
          },
          components: {
            MuiLink: {
              defaultProps: {
                component: LinkBehavior,
              } as LinkProps,
            },
            MuiButtonBase: {
              defaultProps: {
                LinkComponent: LinkBehavior,
              },
            },
          },
        }),
      [LinkBehavior, mode],
    );
    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export const useColorMode = () => {
    return React.useContext(ColorModeContext);
  };