import { api } from './api'

export const enhancedApi = api.enhanceEndpoints({
    endpoints: {
        postUsersLogin: {
            query: (queryArg) => {
                const formData = new FormData();
                formData.append('username', queryArg.bodyUsersUserLogin.username);
                formData.append('password', queryArg.bodyUsersUserLogin.password);
                return {
                  url: `/users/login`,
                  method: 'POST',
                  body: formData,
                  formData:true
                };
              },
            invalidatesTags: ["Users"],
        }
    }
})