
import { Button, Card, CardActions, CardContent, Container, Typography } from '@mui/material';
import { FC } from 'react';
import { useGetUsersMeQuery } from '../store';

export const Profile: FC = () => {
    const {
        data: user,
        isLoading,
    } = useGetUsersMeQuery();

    if (isLoading) return <div>Loading...</div>
    if (!user) return <div>Missing profile!</div>
    return (
        <>
            <Container maxWidth="xs">
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            {user.first_name} {user.last_name}
                        </Typography>
                        <Typography variant="body2">
                            Id : {user.id}
                        </Typography>
                        <Typography variant="body2">
                            Email : {user.email}
                        </Typography>
                        <Typography variant="body2">
                            Phone number : {user.phone_number}
                        </Typography>
                        <Typography variant="body2">
                            Age : {user.age}
                        </Typography>
                        <Typography variant="body2">
                            Address : {user.address}
                        </Typography>
                        <Typography variant="body2">
                            Pass id : {user.pass_id}
                        </Typography>

                    </CardContent>
                    <CardActions>
                        <Button size="small">Learn More</Button>
                    </CardActions>
                </Card>
            </Container>
        </>
    );
};
