import { configureStore } from '@reduxjs/toolkit';
// import initSubscriber from 'redux-subscriber';
import { enhancedApi } from './enhancedApi';
import auth from "./authSlice";

export const store = configureStore({
  reducer: {
    [enhancedApi.reducerPath]: enhancedApi.reducer,
    auth
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(enhancedApi.middleware)
  },
})
// const subscribe = initSubscriber(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch