
import { Box, Container, Grid, Paper } from '@mui/material';
import { FC } from 'react';

export const Logout: FC = () => {


  return (
    <>
      <Paper component={Box} height={"100%"}>
        <Container maxWidth="sm">
          <Grid container spacing={2}>
            <Grid item xs={12} >
              <>
                <h1>Thank you for using Logout !</h1>
              </>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </>
  );
};
