import { FC, ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/AuthProvider";


export const ProtectedRoute : FC<{children: ReactElement<any, any> | null}> = ({ children }) => {
    const { token } = useAuth();
    const location = useLocation();
    
    if (!token) {
      return <Navigate to="/login" replace state={{ from: location }} />;
    }
  
    return children ?? null;
  };