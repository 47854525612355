
import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { FC } from 'react';
import { useGetPlacesGotoByPlaceIdQuery } from '../store/api';
import { useParams } from 'react-router-dom';

export const PlaceShow: FC = () => {

  const { place_id } = useParams();
  const {
    data: place,
    isLoading,
  } = useGetPlacesGotoByPlaceIdQuery({ placeId: place_id ?? "" });

  if (isLoading) return <div>Loading...</div>
  if (!place) return <div>Missing place!</div>

  return (

    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {place.address}
        </Typography>
        <Typography variant="h5" component="div">
          Place id : {place.id}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Phone numbder : {place.phone_number}
        </Typography>
        <Typography variant="body2">
          Required pass level : {place.required_pass_level}
        </Typography>
        <Typography variant="body2">
          Required age level : {place.required_age_level}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>

  );
};
