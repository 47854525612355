import { Button, Typography } from "@mui/material";
import { useAuth } from "../hooks/AuthProvider";


export function LoginOrLogoutButton() {
    const { onLogout, token } = useAuth();

    return (
        <>
            {token ?
                <Button onClick={onLogout}>
                    <Typography textAlign="center">Logout</Typography>
                </Button>
                :
                <Button href="/login">
                    <Typography textAlign="center">Login</Typography>
                </Button>
            }
        </>
    )

}