
import { Container, Link } from '@mui/material';
import { FC } from 'react';
import { useAuth } from '../hooks/AuthProvider';

export const Home: FC = () => {
  const { token } = useAuth();
  return (

    <>
      <Container maxWidth="xs">
        {token ?
          <p><Link href="/places">Places</Link></p>
          :
          <p><Link href="/login">Login</Link></p>
        }
      </Container>
    </>

  );
};
