
import { Container, Link, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { FC } from 'react';
import { useGetUsersMyplacesQuery } from '../store/api';

export const PlacesList: FC = () => {

  const {
    data: places,
    isLoading,
  } = useGetUsersMyplacesQuery();

  if (isLoading) return <div>Loading...</div>
  if (!places) return <div>Missing places!</div>

  return (
    <>
    <Container maxWidth="xs">
      <h1>Places List</h1>
      <List>
        {places.map((place) => (
          <ListItem key={place.id} >
            <ListItemButton >
              <Link href={`/place/${place.id}`} ><ListItemText primary={place.address} /></Link>
            </ListItemButton>
          </ListItem>

        ))}
      </List>
      </Container>
    </>
  );
};
