
import { Box, Container, CssBaseline, Grid, Paper } from '@mui/material';
import AppBar from './components/AppBar';
import { Router } from './routes/Router';
import { CustomThemeProvider } from './hooks/CustomThemeProvider';


export default function App() {

  return (
    <CustomThemeProvider>
      <CssBaseline />
      <AppBar />
      <Container>
        <Paper component={Box} height={"100%"}>
          
            <Grid container spacing={2} alignItems="stretch" >
              <Grid item xs={12} display={"flex"} flexDirection={ "column"}>
                <Box sx={{ minHeight: '80vh' }}>
                <Router />
                </Box>
              </Grid>
            </Grid>
          
        </Paper>
      </Container>
    </CustomThemeProvider>
  );
}
